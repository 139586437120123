import React from 'react';

import Layout from '../components/Layout';

import {Card, MicroCard} from '../components/Card';


import CoverageMap from '../assets/images/img-clouticscoverage.png';
import EverifyImage from '../assets/images/icon-everify.png';
import TempImage from '../assets/images/icon-temporary.svg';
import RemoteImage from '../assets/images/icon-remote.svg';
import EmployeeImage from '../assets/images/icon-employee.svg';

import CloudImage from '../assets/images/icon-cloudenable.svg';
import LowCodeNoCodeImage from '../assets/images/icon-flow.svg';
import TransformImage from '../assets/images/icon-transform.svg';
import SolutionsImage from '../assets/images/icon-inventory.svg';
import PublicSectorImage from '../assets/images/icon-publicsector.svg';
import ModernizeImage from '../assets/images/icon-modernize.svg';

const herobg = '#1c2841', herofcolor='#efefef';
const TalentPage = () => (
  <Layout>
      <div id="main row" className='col-12' >
        <div className='row' style={{backgroundColor:herobg, color:herofcolor, padding:'2em 0'}}>
            <header className='col-9 inner' style={{margin:'.25em auto'}}>
                    <h3 >Talent Services</h3>
                    <h1 style={{letterSpacing:'.1em'}}>
                        Need resources? We are resourceful.
                    </h1>
            </header>
            
        </div>
    
        
        <div className="inner row" style={{margin:'.25em auto'}}>
            <span className="image fit" style={{margin:0}}>
                <img src={CoverageMap} alt="Public Sector" 
                    style={{maxWidth:'600px', margin:'auto'}}/>
            </span>
            <div className='col-9 col-12-medium' style={{marginTop:'10px'}}>
                
            
                <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                    The demand for modern bleeding edge technology is rapidly increasing because of 
                    the technology evolution. We work with our clients to bridge the gap, for sourcing
                    talented technology professionals from the global market. This solves the clients’ 
                    workforce challenges and ultimately, secure their competitive edge.
                    <br/><br/>
                    Our team services the international talent needs of our clients, of all sizes. 
                    Backed by our US, Canada and India offices, we are a proven partner for talent 
                    acquisition strategy. 
                </p> 
                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>We Provide</p>
            
                <div className='row' style={{marginLeft:'0px'}}>
                    <Card cardTitle="Contractual" cardImage={TempImage} >
                        Temp Staffing on Contract basis 
                    </Card>     
                    <Card cardTitle="Permanent" cardImage={EmployeeImage}>
                        Permanant employee placements
                    </Card>
                    <Card cardTitle="Remote" cardImage={RemoteImage}>
                        Remote Workforce engagement
                    </Card>
                </div>
            
                
                <p style={{fontSize:'110%', fontWeight:400, margin:'1em auto .5em'}}>Our focus</p>
                <p style={{ fontSize:'90%', margin:'.5em auto 1em', textAlign:'justify'}}>
                    As part of our talent management solutions, we on-board resources
                    to solve business challenges. We specialize in supplementing resources for 
                    <li>Project Management & Business Analysis</li>
                    <li>Application development</li>
                    <li>Enterprise data management</li>
                    <li>User interface & experiance (UI/UX)</li>
                    <li>Infrastructure & Security</li>

                </p>
            
                <div style={{border:'1px solid #222', borderRadius:'3px', 
                    backgroundColor:'#eee', padding:'.5em', margin:'.5em'}}>
                    <div className="image" style={{alignItems:'center'}}>
                        <img src={EverifyImage} alt="eVerify" style={{width:'100px', margin:'auto'}}/>
                    </div>
                    <p style={{fontSize:'75%', fontWeight:400}}>
                        In the US, Cloutics participates in the government’s E-Verify program which confirms 
                        the employment eligibility of employees.
                        <br/><br/>With strict compliance in place, we adhere to US Citizenship and Immigration Services 
                        (USCIS) and Department of Labor (DOL) requirements. Our internal audit process helps us evaluate immigration/labor adherence to compliance.

                    </p>
                </div>
            </div>
            <div className='col-3 col-6-medium col-12-small' >
                <p style={{fontWeight:400, fontSize:'90%', margin:'5px 0 2px 0'}}>Explore</p>
                    <MicroCard className='col-12 col-6-medium' 
                            cardTitle="Transformation" cardImage={TransformImage} 
                            cardLink='/transform-your-business'/>
                    <MicroCard className='col-12 col-6-medium' 
                            cardTitle="Business Solutions" cardImage={SolutionsImage} 
                            cardLink='/business-solutions-on-the-cloud'/>
                    <MicroCard className='col-12 col-6-medium' 
                            cardTitle="Public Sector Services" cardImage={PublicSectorImage} 
                            cardLink='/public-sector-services'/>
                    <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Low Code/No Code" cardImage={LowCodeNoCodeImage} 
                        cardLink='/lowcode-nocode'/>
                    <MicroCard className='col-12 col-6-medium' 
                        cardTitle="Modernize" cardImage={ModernizeImage} 
                        cardLink='/modernize-solutions'/>
                    <MicroCard className='col-12 col-6-medium' 
                            cardTitle="Enable the Cloud" cardImage={CloudImage} 
                            cardLink='/enable-the-cloud'/>
                    
                            
                    
            </div>
        </div>
    </div>
  </Layout>
);

export default TalentPage;
